.video-container{
    width: 40rem;
}

.video{
    width: 100%;
    padding-top: 56.25%;
}

@media screen and (max-width: 40rem) {
    .video-container{
        width: 100%;
        padding: 0 2px;
    }
}