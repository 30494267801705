@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono');

//$easeIn: cubic-bezier(0.25, 0.46, 0.45, 0.94)
//$easeOut: cubic-bezier(0.55, 0.085, 0.68, 0.53)
$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$turnDuration: 0.6s;


$border: #3a9269;
$card: #0074ad;
$font-family: 'Droid Sans Mono',
monospace;
$font-size: 2.5em;
$font-width: bold;
$font-color: lighten(white, 20%);


// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

// Animation mixin
@mixin animate($name, $easing, $duration: 300ms, $delay: 0s, $iteration: 1, $direction: normal, $fill-mode: forwards) {
	animation: $name $duration $easing $delay $iteration $direction $fill-mode;
}

@keyframes fold {
	0% {
		transform: rotateX(0deg);
	}

	100% {
		transform: rotateX(-180deg);
	}
}

@keyframes unfold {
	0% {
		transform: rotateX(180deg);
	}

	100% {
		transform: rotateX(0deg);
	}
}

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

.clock-center {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 12px;
	border: 1px solid $border;
	background-color: #555;

	&.left {
		left: 0;

	}

	&.right {
		right: 0;
	}
}

.FlipClock {
	display: flex;
	// justify-content: space-between;
	justify-content: space-around;
	// width: 3 * 50px + 21px;
	width: 100%;
	height: 100%;


	.flipUnitContainer {
		display: block;
		position: relative;
		width: 76px;
		height: 76px;
		perspective-origin: 50% 50%;
		perspective: 300px;
		background-color: $card;
		border-radius: 12px;
		box-shadow: 0px 10px 10px -10px grey;

		.ContainerTitle {
			// position: absolute;
			// text-align: center;
			// width: 100%;
			// bottom: 0;
			// font-size: 20px;
			// text-transform: uppercase;
			font-size: 16px;
			text-align: center;
			font-weight: 700;
			margin-top: 10px;
		}


		.upperCard,
		.lowerCard {
			display: flex;
			position: relative;
			justify-content: center;
			width: 76px;
			height: 38px;
			overflow: hidden;
			// border: 1px solid $border;

			span {
				font-size: $font-size;
				font-family: $font-family;
				font-weight: $font-width;
				color: $font-color;
			}
		}

		.upperCard {
			align-items: flex-end;
			border-bottom: 1px solid $border;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			span {
				transform: translateY(50%);
			}
		}

		.lowerCard {
			align-items: flex-start;
			border-top: 1px solid $border;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;

			span {
				transform: translateY(-50%);
			}
		}

		.flipCard {
			display: flex;
			justify-content: center;
			position: absolute;
			left: 0;
			width: 100%;
			height: 50%;
			overflow: hidden;
			backface-visibility: hidden;

			span {
				font-family: $font-family;
				font-size: $font-size;
				font-weight: $font-width;
				color: $font-color;
			}

			&.unfold {
				top: 50%;
				align-items: flex-start;
				transform-origin: 50% 0%;
				transform: rotateX(180deg); // from 180 to 0
				background-color: $card;
				border-bottom-left-radius: 12px;
				border-bottom-right-radius: 12px;
				// border: 1px solid $border;
				border-top: 1px solid $border;

				span {
					transform: translateY(-50%);
				}

				@include animate(unfold, $easeInOut, 0.6s);
				transform-style: preserve-3d;
			}

			&.fold {
				top: 0%;
				align-items: flex-end;
				transform-origin: 50% 100%;
				transform: rotateX(0deg); // from 0 to -180
				background-color: $card;
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
				// border: 1px solid $border;
				border-bottom: 1px solid $border;

				span {
					transform: translateY(50%);
				}

				@include animate(fold, $easeInOut, 0.6s);
				transform-style: preserve-3d;
			}
		}

	}

	.divider {
		font-size: 10px;
		display: none;
		margin: auto 3px;
	}
}

@media screen and (max-width: 767px) {
	.FlipClock .flipUnitContainer {
		width: 60px;
		height: 60px;

		.upperCard,
		.lowerCard {
			width: 60px;
			height: 30px;

			& span {
				font-size: 1.8rem;
			}
		}

		.flipCard {
			& span {
				font-size: 1.8rem;
			}
		}
	}
}